// 导入表格弹框样式
.LigandUpModal.ant-modal {
  .ant-modal-content {
    padding: 0;
    .ant-modal-close-x {
      width: auto;
    }
  }
  .ant-modal-body {
    padding: 12px 24px;
  }
  .tips {
    background-color: #edf7fd;
    padding: 10px;
    border-left: 10px solid #09aaff;
    margin-bottom: 20px;
    border-radius: 6px 0 0 6px;
    font-size: 14px;

    p {
      font-size: 14px;
      margin-bottom: 0;
      span {
        color: #09aaff;
        cursor: pointer;
      }
    }
  }
  .files {
    width: 100%;
    height: 40px;
    border: 1px dashed #09aaff;
    margin-bottom: 10px;
    position: relative;

    .uploadUI {
      position: absolute;
      width: 100%;
      line-height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #09aaff;
    }

    &:hover {
      border: 1.2px dashed #09aaff;

      .uploadUI {
        color: #09aaff;
        font-weight: bold;
      }
    }

    #fileExcel {
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }

  .selectedFile {
    margin-top: 30px;

    p {
      margin-top: 5px;

      &:hover {
        background-color: #f1f1f1;
      }
    }

    .upload_line {
      width: 100px;
      background-color: #cccccc;
      height: 2px;
      margin-top: 10px;

      .current_line {
        background-color: goldenrod;
        height: 2px;
        transition: width 0.5s;
        -moz-transition: width 0.5s;
        /* Firefox 4 */
        -webkit-transition: width 0.5s;
        /* Safari 和 Chrome */
        -o-transition: width 0.5s;
      }
    }
  }

  .ant-modal-content {
    .ant-modal-body {
      .Tips_text {
        color: red;
        padding-bottom: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 12px;

        span {
          color: red;
          font-weight: bold;
        }

        .downTem {
          color: #09aaff;
          margin-left: 20px;
          cursor: pointer;
        }
      }
    }
  }

  // 解析进度条
  .analysis_progress {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    .analysis_line {
      width: 250px;
      height: 4px;
      background-color: #cccccc;
      margin-right: 10px;

      .current_line {
        height: 100%;
        background-color: goldenrod;
        transition: width 0.5s;
        -moz-transition: width 0.5s;
        /* Firefox 4 */
        -webkit-transition: width 0.5s;
        /* Safari 和 Chrome */
        -o-transition: width 0.5s;
      }
    }

    .analysis_num {
      flex: 1;
      display: flex;

      .text {
        margin-right: 20px;
        font-weight: bold;
      }

      .number {
        .err {
          color: red;
        }

        .succ {
          color: green;
        }
      }
    }
  }
}

.parsing_right_messge {
  .ant-notification-notice-message {
    span {
      color: red;
    }
  }
}
